/* eslint-disable react/prop-types */

import React, { Component } from "react";

import { AppContext } from "~context/AppContext";

import KlaviyoForm from "~components/KlaviyoForm";

class FormFooterComponent extends Component {
  componentDidMount() {}

  //

  render() {
    return (
      <section className="form-footer relative pt-4 px-6 xs:px-2 bg-sun-yellow">
        <KlaviyoForm className="pt-4 pb-8" klaviyoId="Kqkz9T" footer>
          <section className="grid-end-4 grid-start-1 xs:grid-end-12 flex flex-row items-end pb-2">
            Is your inbox ugly? <br />
            Sign up and we’ll make it pretty.
          </section>
        </KlaviyoForm>
      </section>
    );
  }
}

const FormFooter = props => {
  const { headlineText } = props;
  return (
    <AppContext.Consumer>
      {appContext => (
        <FormFooterComponent
          appContext={appContext}
          headlineText={headlineText}
        />
      )}
    </AppContext.Consumer>
  );
};

export default FormFooter;
